(function ($, AccessibleDisclosureButton) {
  var contentBlockSitewideBanner = {
    init: function (context) {
      var self = this;
      var disclosureButton;
      var $body = $('body');
      var $gnavHeaderBlock = $body.find('.js-gnav-header-block-v1');
      var moduleHeight = 0;
      var moduleCookieName = '';
      var hasModuleCookie = false;
      if (self.attached) {
        return;
      }
      self.attached = true;
      self.$module = $('.js-content-block-sitewide-banner--v1', context);
      self.$triggers = $('.js-content-block-sitewide-banner-trigger', self.$module);
      var pageUrlBanner = self.$module.attr('data-module-page-url') || null;
      var urlBannerArray = pageUrlBanner && pageUrlBanner.length ? pageUrlBanner.split(',') : [];
      var bannerRemoval = false;
      if (urlBannerArray.length > 0) {
        $.each(urlBannerArray, function (index) {
          var pageNamePath = window.location.pathname;
          if (pageNamePath.match(urlBannerArray[index])) {
            self.$module.remove();
            $gnavHeaderBlock.removeClass('has-sitewide-banner');
            $body.removeClass('content-block-sitewide-banner--enabled');
            return (bannerRemoval = true);
          }
        });
        if (bannerRemoval) {
          return false;
        }
      }
      disclosureButton = new AccessibleDisclosureButton(self.$triggers.get(0));
      if (self.$module.length < 1) {
        return false;
      }
      moduleCookieName = self.$module.data().moduleCookieName;
      hasModuleCookie = $.cookie(moduleCookieName);
      if (hasModuleCookie) {
        self.$module.detach();
        $(document).trigger('contentBlock.sitewideBanner', [0]);

        $gnavHeaderBlock.removeClass('has-sitewide-banner');
        self.$module.addClass('hidden');
        moduleHeight = self.$module.outerHeight(false);
        return;
      }
      self.$module.removeClass('hidden');
      disclosureButton.init();
      $body.addClass('content-block-sitewide-banner--enabled');

      moduleHeight = self.$module.outerHeight(true);
      $(document).trigger('contentBlock.sitewideBanner', [moduleHeight]);
      if (self.$triggers.data('expires')) {
        self.$triggers.on('click', function () {
          $gnavHeaderBlock.removeClass('has-sitewide-banner');
          $body.removeClass('content-block-sitewide-banner--enabled');
          $(document).trigger('contentBlock.sitewideBanner', [0]);
          self.setCookie();
          self.$module.remove();
        });
      }
    },
    setCookie: function () {
      var self = this;

      $.cookie(self.$triggers.attr('aria-controls'), 1, {
        path: '/',
        expires: self.$triggers.data('expires')
      });
    }
  };

  Drupal.behaviors.contentBlockSitewideBannerV1 = {
    $module: $(),
    $triggers: $(),
    attached: false,
    attach: function (context) {
      contentBlockSitewideBanner.init(context);
    }
  };

  $(document).on('contentBlock.signupSuccess', function () {
    contentBlockSitewideBanner.setCookie();
  });
})(jQuery, window.AccessibleDisclosureButton);
